import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import AllCurlLanding from 'components/CustomLanding/AllCurlLanding';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function AllCurlLandingPage(props) {
    let {
        data: { contentfulCustomLandingPage, allContentfulEvent },
    } = props;

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulCustomLandingPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulCustomLandingPage, 'seoListing.seoTitle')}
                slug={'texture-guide'}
            />
            <AllCurlLanding
                page={contentfulCustomLandingPage}
                events={allContentfulEvent.nodes}
            />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        contentfulCustomLandingPage(
            node_locale: { eq: $locale }
            slug: { eq: "all-curl" }
        ) {
            ...ContentfulCustomLandingPageFields
            section1Data {
                fragments {
                    key
                    value
                    type
                }
            }
        }
        allContentfulEvent(filter: { node_locale: { eq: $locale } }) {
            nodes {
                name
                url
                image {
                    localFile {
                        publicURL
                    }
                }
                date
                endTime
                shortDescription {
                    shortDescription
                }
                aboutThisEvent {
                    aboutThisEvent
                }
                aboutThisEventRtf {
                    raw
                }
                aboutTheInstructor {
                    aboutTheInstructor
                }
                preparation {
                    preparation
                }
                bookingButtonText
                bookingButtonLink
                whereCoOrdinates {
                    lat
                    lon
                }
                where {
                    where
                }
                node_locale
                region
                seoListing {
                    ...ContentfulSeoListingFields
                }
            }
        }
    }
`;
