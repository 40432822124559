import React from 'react'
import get from 'lodash.get'
import HeroSlider from 'components/HeroSlider'
import { injectIntl } from 'gatsby-plugin-react-intl'
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube'
import url from 'helpers/url'
import Videos from 'blocks/Videos'
import CustomLink from 'components/Link'
import { parseISO, format } from 'date-fns'

import {
    Spacing,
    SharedStyles,
    Container,
    VideoBlock,
    NewVideoWithDesc,
    GenericBenefitList,
    GenericProductList,
    ListOfImg,
    EventDetail,
} from '../../kenra-storybook/index'

const {
    StSectionInfo,
    StSectionTitle,
    StLargeSectionTitle,
    StSectionBody,
    StSectionLink,
    VidTitle,
    StTitle,
    StLinkMore,
} = SharedStyles

const {
    StEventDetail,
    StEventDetailLeftCol,
    StEventDetailRightCol,
    StEventDetailMainImg,
    StEventDetailAboutLeftCol,
    StEventDetailAboutRightCol,
    StEventDetailAboutContainer,
    StEventDetailWhenContainer,
    StEventDetailIconTitle,
    StEventDetailLine,
    StDirectionButtonLink,
    StEventDetailFAQLink,
    StEventDetailInstructorContainer,
    StEventDetailInstructorImg,
} = EventDetail

function parseFragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Image':
                    returnData['img'] = fragmentData.value
                    break
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Text':
                    returnData['text'] = fragmentData.value
                    if (fragmentData.value !== null) {
                        returnData['text'] = fragmentData.value.replace(
                            '\\n',
                            '\n '
                        )
                    }
                    break
                case 'ProductLink':
                    returnData['link'] = fragmentData.value
                    break
                case 'Overlay':
                    returnData['overlay'] = fragmentData.value
                    break
                default:
                    break
            }
        })
        return {
            image: returnData.img,
            title: returnData.title,
            text: returnData.text,
            link: returnData.link,
            linkText: intl.formatMessage({
                id: 'kenra-shop-now',
            }),
            overlay: returnData.overlay,
        }
    })

    return fragmentDataMap
}

function parseSection3Fragments(fragments, intl) {
    let fragmentDataMap = fragments.map(section => {
        let returnData = {}
        section.forEach(fragmentData => {
            switch (fragmentData.key) {
                case 'Title':
                    returnData['title'] = fragmentData.value
                    break
                case 'Subtitle':
                    returnData['subtitle'] = fragmentData.value
                    break
                case 'Button1Text':
                    returnData['button1Text'] = fragmentData.value
                    break
                case 'Button1Url':
                    returnData['button1Url'] = fragmentData.value
                    break
                case 'Button2Text':
                    returnData['button2Text'] = fragmentData.value
                    break
                case 'Button2Url':
                    returnData['button2Url'] = fragmentData.value
                    break
                case 'Image':
                    returnData['image'] = fragmentData.value
                    break
                default:
                    break
            }
        })
        return {
            title: returnData.title,
            subtitle: returnData.subtitle,
            button1Text: returnData.button1Text,
            button1Url: returnData.button1Url,
            button2Text: returnData.button2Text,
            button2Url: returnData.button2Url,
            image: returnData.image,
        }
    })

    return fragmentDataMap
}

const AllCurlLanding = ({ intl, page, events }) => {
    let section1Data = parseFragments(page.section1Data.fragments, intl)
    let collectionData = parseFragments(page.collectionData.fragments, intl)
    let moreCollectionData = parseFragments(
        page.moreCollectionData.fragments,
        intl
    )
    let section3Data = parseSection3Fragments(
        page.section3Data.fragments,
        intl
    )

    // Find the event we need
    let allCurlEvent = events.find(event => event.url === page.eventSlug)
    let tz = null
    if (typeof window !== `undefined` && navigator && navigator.language) {
        tz = /.*\s(.+)/.exec(
            new Date().toLocaleDateString(navigator.language, {
                timeZoneName: 'short',
            })
        )
    }
    const tzName = tz && tz.length > 1 && tz[1] ? tz[1] : null

    let whereLines = null
    if (allCurlEvent.where && allCurlEvent.where.where) {
        whereLines = allCurlEvent.where.where.split('\n')
    }

    let tutorials = page.tutorials
    let youtubeLink = tutorials[0].youtubeLink
    let youtubeLink2 = tutorials[1].youtubeLink

    let youTubeId = getYouTubeId(youtubeLink)
    let youTubeId2 = getYouTubeId(youtubeLink2)

    let background = get(page, 'backgroundImage.localFile.publicURL')

    return (
        <>
            <Spacing removeSpaceTop removeSpaceBottom>
                <VideoBlock
                    renderVideo
                    imgUrl={get(page, 'videoBannerPreview.localFile.publicURL')}
                >
                    <video preload="metadata">
                        <source
                            src={get(page, 'videoBanner.localFile.publicURL')}
                            type="video/mp4"
                        />
                        {intl.formatMessage({
                            id: 'kenra-video-not-supported',
                        })}
                    </video>
                </VideoBlock>
            </Spacing>
            <hr
                style={{
                    backgroundColor: page.collectionTitleColor,
                    margin: '0px',
                    height: '30px',
                    borderBottom: '0px',
                }}
            />
            <Spacing removeSpaceTop>
                <HeroSlider slides={page.banner} />
            </Spacing>
            <Spacing removeSpaceTop removeSpaceBottom>
                <StSectionInfo>
                    <Container>
                        <StTitle>
                            <h2>{get(page, 'section1Title')}</h2>
                        </StTitle>
                        <p>{get(page, 'section1Subtitle')}</p>
                    </Container>
                </StSectionInfo>
            </Spacing>
            <Spacing removeSpaceTop>
                {section1Data && <GenericBenefitList items={section1Data} />}
            </Spacing>
            <Spacing removeSpaceBottom>
                {page.gifImages && (
                    <ListOfImg
                        items={page.gifImages.map(gif => {
                            return {
                                img: get(gif, 'localFile.publicURL'),
                            }
                        })}
                    />
                )}
            </Spacing>
            <Spacing>
                <div>
                    {page.sustainabilityTitle && (
                        <div>
                            <StTitle>
                                <h2
                                    style={{ color: page.collectionTitleColor }}
                                >
                                    {page.sustainabilityTitle}
                                </h2>
                            </StTitle>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '70%',
                                    margin: 'auto',
                                }}
                            >
                                {page.sustainabilityImages.map(image => (
                                    <div style={{ margin: '20px' }}>
                                        <img
                                            src={get(
                                                image,
                                                'localFile.publicURL'
                                            )}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Spacing>
            <Spacing>
                <StTitle style={{ color: page.collectionTitleColor }}>
                    <h2
                        style={{
                            color: page.collectionTitleColor,
                            backgroundColor: '#E5EFE6',
                            width: '60%',
                            margin: 'auto',
                        }}
                    >
                        {page.section2Text}
                    </h2>
                </StTitle>
            </Spacing>
            <Spacing backgroundImage={background}>
                <StSectionInfo>
                    <Container fullWidth>
                        <StTitle color={page.collectionTitleColor}>
                            <h2>{get(page, 'collectionTitle')}</h2>
                        </StTitle>
                    </Container>
                </StSectionInfo>
                {collectionData && (
                    <GenericProductList
                        color={page.collectionTitleColor}
                        items={collectionData}
                        split5050
                        itemColor={page.collectionTitleColor}
                        leftJustify
                    />
                )}
                <Spacing>
                    <StSectionInfo>
                        <Container fullWidth>
                            <StTitle color={page.collectionTitleColor}>
                                <h2>{get(page, 'moreCollectionTitle')}</h2>
                            </StTitle>
                        </Container>
                    </StSectionInfo>
                    {moreCollectionData && (
                        <GenericProductList
                            color={page.collectionTitleColor}
                            items={moreCollectionData}
                            split5050
                            itemColor={page.collectionTitleColor}
                            leftJustify
                        />
                    )}
                </Spacing>
            </Spacing>
            <Spacing>
                <section>
                    <div>
                        <Container>
                            <VidTitle>
                                {intl.formatMessage({
                                    id: 'kenra-latest-tutorials',
                                })}
                            </VidTitle>
                        </Container>
                    </div>

                    <NewVideoWithDesc
                        text={tutorials[0].title.title}
                        text2={tutorials[1].title.title}
                        linkMoreText={intl.formatMessage({
                            id: 'video-view-all',
                        })}
                        linkMoreHref={url.videos}
                        bgImg={getPreviewUrl(youTubeId)}
                        bgImg2={getPreviewUrl(youTubeId2)}
                        videoOptions={[{ src: getEmbedUrl(youTubeId) }]}
                        videoOptions2={[{ src: getEmbedUrl(youTubeId2) }]}
                        btnBgColor={page.collectionTitleColor}
                    />
                </section>
            </Spacing>
            <Spacing>
                <Videos
                    title={intl.formatMessage({ id: 'kenra-education-videos' })}
                    videos={page.educationVideos}
                    bgImage={get(
                        page,
                        'educationVideoImage.localFile.publicURL'
                    )}
                />
            </Spacing>
            <Spacing>
                <Container>
                    <div style={{ border: '1px solid #000', display: 'flex' }}>
                        <div
                            style={{
                                width: '50%',
                                textAlign: 'center',
                                margin: 'auto',
                            }}
                        >
                            <StTitle>
                                <h2>{section3Data[0].title}</h2>
                            </StTitle>
                            <div style={{ fontSize: '18px', margin: '30px' }}>
                                {section3Data[0].subtitle}
                            </div>
                            <StLinkMore
                                color={page.collectionTitleColor}
                                style={{ marginBottom: '30px' }}
                            >
                                <CustomLink href={section3Data[0].button1Url}>
                                    {section3Data[0].button1Text}
                                </CustomLink>
                            </StLinkMore>
                            <StLinkMore color={page.collectionTitleColor}>
                                <CustomLink href={section3Data[0].button2Url}>
                                    {section3Data[0].button2Text}
                                </CustomLink>
                            </StLinkMore>
                        </div>
                        <div style={{ width: '50%' }}>
                            <img src={section3Data[0].image} />
                        </div>
                    </div>
                </Container>
            </Spacing>
            <Spacing removeSpaceBottom>
                <StSectionInfo>
                    <Container style={{ maxWidth: '1070px' }}>
                        <StEventDetail>
                            <StEventDetailLeftCol>
                                {allCurlEvent.image && (
                                    <StEventDetailMainImg
                                        style={{
                                            backgroundImage: `url(${get(
                                                allCurlEvent.image,
                                                'localFile.publicURL'
                                            )}`,
                                        }}
                                    />
                                )}
                            </StEventDetailLeftCol>
                            <StEventDetailRightCol>
                                <StEventDetailWhenContainer>
                                    <StEventDetailIconTitle>
                                        <img src="https://static.thenounproject.com/png/376090-200.png" />
                                        <h3>
                                            {' '}
                                            {intl.formatMessage({
                                                id: 'event-when-occurs',
                                            })}
                                        </h3>
                                    </StEventDetailIconTitle>
                                    {allCurlEvent.date && (
                                        <h2>
                                            {format(
                                                parseISO(allCurlEvent.date),
                                                'MMMM d, Y'
                                            )}
                                        </h2>
                                    )}
                                    {allCurlEvent.date && allCurlEvent.endTime && (
                                        <h4>
                                            {format(
                                                parseISO(allCurlEvent.date),
                                                'h:mm'
                                            )}
                                            &ndash;
                                            {format(
                                                parseISO(allCurlEvent.endTime),
                                                'h:mma'
                                            )}
                                            {tzName ? ` ${tzName}` : ``}
                                        </h4>
                                    )}
                                    {allCurlEvent.date &&
                                        !allCurlEvent.endTime && (
                                            <h4>
                                                {format(
                                                    parseISO(allCurlEvent.date),
                                                    'h:mma'
                                                )}
                                                {tzName ? ` ${tzName}` : ``}
                                            </h4>
                                        )}
                                    <StEventDetailLine />
                                    <StEventDetailIconTitle>
                                        <img src="https://static.thenounproject.com/png/376090-200.png" />
                                        <h3>
                                            {' '}
                                            {intl.formatMessage({
                                                id: 'event-where-occurs',
                                            })}
                                        </h3>
                                    </StEventDetailIconTitle>
                                    {whereLines && (
                                        <>
                                            {whereLines.length > 0 &&
                                                whereLines[0] && (
                                                    <h2>{whereLines[0]}</h2>
                                                )}
                                            {whereLines.length > 1 &&
                                                whereLines[1] && (
                                                    <h4>{whereLines[1]}</h4>
                                                )}
                                            {whereLines.length > 2 &&
                                                whereLines[2] && (
                                                    <h4
                                                        style={{
                                                            fontWeight: 500,
                                                            paddingTop: '10px',
                                                            paddingBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        {whereLines[2]}
                                                    </h4>
                                                )}
                                        </>
                                    )}
                                    <StLinkMore>
                                        <a
                                            style={{
                                                minWidth: '80%',
                                                marginLeft: '20px',
                                                marginRight: '20px',
                                            }}
                                            href={
                                                allCurlEvent.bookingButtonLink
                                            }
                                        >
                                            {allCurlEvent.bookingButtonText ||
                                                intl.formatMessage({
                                                    id: 'event-book-now',
                                                })}
                                        </a>
                                    </StLinkMore>
                                    <StEventDetailFAQLink>
                                        <p>
                                            {intl.formatMessage({
                                                id: 'event-questions-1',
                                            })}{' '}
                                            <a href={`https://kenraprofessional.zendesk.com/hc/en-us`}>
                                                {intl.formatMessage({
                                                    id: 'event-questions-2',
                                                })}
                                            </a>
                                        </p>
                                    </StEventDetailFAQLink>
                                </StEventDetailWhenContainer>
                            </StEventDetailRightCol>
                        </StEventDetail>
                    </Container>
                </StSectionInfo>
            </Spacing>
        </>
    )
}

export default injectIntl(AllCurlLanding)
